<template>
  <CategoriesMB v-if="layout == 4" />
  <CategoriesPC :key="categoryId" v-else />
</template>

<script>
import CategoriesMB from "@/pages/mb/Categories";
import CategoriesPC from "@/pages/pc/Categories";
import CategoryService from "@/services/category";
import { ref, provide } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import { useRoute } from "vue-router";

export default {
  components: {
    CategoriesMB,
    CategoriesPC,
  },
  setup() {
    const route = useRoute();
    const categoryId = ref(route.params.cid);
    const categoryData = ref({});
    const loading = ref(true);

    const params = ref({
      page: 1,
      type_category: route.query.type_category ? route.query.type_category : "",
      sort_by: route.query.sort_by ? route.query.sort_by : "random", //popularity , percent_discount
      sort_order: route.query.sort_order ? route.query.sort_order : "desc",
      sizes: route.query.sizes ? route.query.sizes : [],
      company_ids: route.query.company_ids ? route.query.company_ids : [],
      color: route.query.color ? route.query.color : [],
    });

    getCategory();

    async function getCategory() {
      const response = await CategoryService.fetchCategory(categoryId.value);
      if (response.data) {
        categoryData.value = response.data;
        document.title = categoryData.value.page_title;
        loading.value = false;
      }
    }

    onBeforeRouteUpdate((to, from) => {
      loading.value = true;
      categoryId.value = to.params.cid;
      getCategory();
    });

    provide("categoryData", categoryData);
    provide("params", params);
    provide("loading", loading);

    return {
      categoryId,
    };
  },
};
</script>
