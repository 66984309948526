<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <LoadingComponent v-if="loading" />
    <div v-else>
      <div class="dosiin_wrapper-bar dosiin_mb-16">
        <Breadcrumbs :breadcumbs="breadcumbs" />
        <div class="dosiin-tab-bar-pc">
          <div class="tab-bar-pc-content">
            <div class="title-page d-flex align-items-center justify-content-between">
              <div
                class="title-all-categories"
                v-text="`${categoryData.category} ${text_type_category}`"
              ></div>
              <div class="tab-bar-content-quantity">
                <span class="quantity" v-text="numberFormat(totalProducts)"></span>
                <span class="text-quantity">Sản phẩm tìm thấy</span>
              </div>
            </div>
          </div>

          <div
            class="tab-bar-pc-line"
            v-if="categoryData.level == 1 && categoryData.children"
          ></div>

          <div
            class="tab-bar-content-item dosiin_mb-8"
            v-if="categoryData.level == 1 && categoryData.children"
          >
            <span
              class="tab-bar-content-item-detail active line-active"
              v-if="dosiinEmptyObject(categoryData.children.length)"
            >
              Toàn bộ
            </span>

            <template v-if="Object.keys(route.query).length == 0">
              <router-link
                class="tab-bar-content-item-detail"
                :to="_categoryUrl(child)"
                v-for="(child, index) in categoryData.children"
                :key="index"
              >
                {{ child.category }}
              </router-link>
            </template>

            <template v-else>
              <router-link
                class="tab-bar-content-item-detail"
                :to="{
                  path: _categoryUrl(child),
                  query: { type_category: route.query.type_category },
                }"
                v-for="(child, index) in categoryData.children"
                :key="index"
              >
                {{ child.category }}
              </router-link>
            </template>
          </div>

          <div
            class="tab-bar-pc-line dosiin_mb-4"
            v-if="categoryData.level == 1 && categoryData.children"
          ></div>

          <FitersBarPc
            :filterCategory="false"
            :maxPrice="maxPrice"
            :totalProducts="totalProducts"
          />
        </div>
      </div>

      <div class="page-section">
        <ProductInifityLoader
          :query="params"
          @total-products="(total) => (totalProducts = total)"
          @max-price="(max) => (maxPrice = max)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, watch } from "vue";
import ProductInifityLoader from "@/components/product/ProductInifityLoader";
import FitersBarPc from "@/components/filters/FiltersBarPc";
import { _categoryUrl } from "@/utlis/helpers";
import { useRoute } from "vue-router";
export default {
  components: {
    ProductInifityLoader,
    FitersBarPc,
  },
  setup() {
    const totalProducts = ref(0);
    const categoryData = inject("categoryData");
    const params = inject("params");
    const loading = inject("loading");
    const maxPrice = ref(0);
    const breadcumbs = ref([
      //{'title' : 'Danh mục', 'route' : '#'},
    ]);
    const route = useRoute();
    const text_type_category = ref("");

    watch(categoryData, () => {
      if (categoryData.value) {
        if (categoryData.value.level == 2) {
          const parent = categoryData.value.parent;

          if (Object.keys(route.query).length !== 0) {
            breadcumbs.value.push({
              title: parent.category,
              route: _categoryUrl(parent),
              type_category: route.query.type_category,
            });
          } else {
            breadcumbs.value.push({
              title: parent.category,
              route: _categoryUrl(parent),
            });
          }
        }

        breadcumbs.value.push({ title: categoryData.value.category, route: "#" });

        if (Object.keys(route.query).length !== 0) {
          if (route.query.type_category == "male") {
            text_type_category.value = "Nam";
          } else if (route.query.type_category == "female") {
            text_type_category.value = "Nữ";
          }
        }
      }
    });

    return {
      params,
      loading,
      maxPrice,
      breadcumbs,
      categoryData,
      totalProducts,
      route,
      text_type_category,
    };
  },
};
</script>
