<template>
  <main class="product-level1-page page-new-product">
    <BackHeader>
      <template v-slot:center>
        <h2
          class="text-grey-lg title-header-category"
          v-text="categoryData.category"
        ></h2>
      </template>
      <template v-slot:right>
        <div class="back-header-right-side">
          <router-link
            :to="{ name: 'search' }"
            class="search-icon dosiin_search-header-btn"
          >
            <i class="dsi dsi-search"></i>
          </router-link>
        </div>
      </template>
    </BackHeader>

    <LoadingComponent v-if="loading" />
    <div v-else>
      <div class="product-page-header_wrap">
        <h1
          class="title 123"
          v-text="`${categoryData.category} ${text_type_category}`"
        ></h1>
        <h2
          class="subtitle"
          v-if="categoryData.meta_description"
          v-html="
            categoryData.meta_description
              .replace('{{sex}}', '')
              .replace('{{brand}}', '')
              .replace('{{month}}', currentMonth)
              .replace('{{year}}', currentYear)
          "
        ></h2>
      </div>

      <nav class="sticky-nav-bar" v-if="categoryData.level == 1 && categoryData.children">
        <div class="category-nav-bar">
          <div class="dosiin_nav-bar-swiper-container nav-bar-swiper-container">
            <Swiper class="swiper-wrapper" :freeMode="true" slidesPerView="auto">
              <SwiperSlide class="swiper-slide_underline swiper-slide active">
                <a class="dosiin_change-category" title="Toàn bộ">Toàn bộ</a>
              </SwiperSlide>
              <SwiperSlide
                class="swiper-slide_underline swiper-slide"
                v-for="(child, index) in categoryData.children"
                :key="index"
              >
                <router-link
                  :to="_categoryUrl(child)"
                  :title="child.category"
                  class="dosiin_change-category"
                >
                  {{ child.category }}
                </router-link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </nav>

      <div class="page-nav-bar-sticky">
        <div
          class="page-nav-bar dosiin_page-nav-bar dosiin_filter-result-nav-bar filter-result-nav-bar"
        >
          <div class="total-result" style="display: flex">
            <b class="total-product">{{ numberFormat(totalProducts) }}</b> &nbsp;sản phẩm
          </div>
          <button class="nav-bar_filter" type="button" @click="openFilters">
            <div class="divider"></div>
            <span class="text">Bộ lọc</span>
            <i class="dsi dsi-filter-setting"></i>
          </button>
        </div>
      </div>

      <div class="page-section">
        <h2 class="title-product-level1-view-all">Sản phẩm</h2>
        <ProductInifityLoader
          :query="params"
          gridClass="grid-33"
          :changeGrid="true"
          @total-products="(total) => (totalProducts = total)"
          @max-price="(max) => (maxPrice = max)"
        />
      </div>

      <MainFilters
        v-model="params"
        :filterCategory="false"
        :totalProducts="totalProducts"
        :maxPrice="maxPrice"
        filterModalClass="modal-sidebar-right"
        ref="mainFilters"
      />
    </div>
  </main>
</template>

<script>
import BackHeader from "@/components/common/BackHeader";
import MainFilters from "@/components/filters/Filters";
import { ref, inject, watch } from "vue";
import ProductInifityLoader from "@/components/product/ProductInifityLoader";
import { _categoryUrl } from "@/utlis/helpers";
import { useRoute } from "vue-router";

export default {
  components: {
    BackHeader,
    MainFilters,
    ProductInifityLoader,
  },
  setup() {
    const categoryData = inject("categoryData");
    const currentMonth = inject("currentMonth");
    const currentYear = inject("currentYear");
    const mainFilters = ref(null);
    const totalProducts = ref(0);
    const maxPrice = ref(0);
    const params = inject("params");
    const loading = inject("loading");
    const openFilters = () => {
      mainFilters.value.show = !mainFilters.value.show;
    };
    const route = useRoute();
    const text_type_category = ref("");

    watch(categoryData, () => {
      if (categoryData.value) {
        if (Object.keys(route.query).length !== 0) {
          if (route.query.type_category == "male") {
            text_type_category.value = "Nam";
          } else if (route.query.type_category == "female") {
            text_type_category.value = "Nữ";
          }
        }
      }
    });

    return {
      loading,
      categoryData,
      currentMonth,
      currentYear,
      params,
      totalProducts,
      maxPrice,
      // refs
      mainFilters,
      // //methods
      openFilters,
      route,
      text_type_category,
    };
  },
};
</script>
